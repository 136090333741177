import Footer from "./Footer";
import "../../Styles/guideline.css";
const Guideline = () => {
  return (
    <>
      <body id="guideline-body">
        <div className="guideline-body">
          <div class="dubslogo-header grid lg:grid-cols-3 gap-0 lg:gap-4 py-2 lg:py-4 px-14">
            <div class="dubs-logo mt-2 flex lg:block justify-center">
              <img
                className="h-12"
                src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
              />
            </div>
            <div class="header">
              <h1 className="text-center">Dubs Community Guidelines</h1>
            </div>
          </div>
          {/* <div className='dubslogo-header  flex flex-col justify-center'> */}
          {/* <div className=' mt-[1rem] mb-[2rem] dubs-web-logo'>
          <div className='flex   w-full ml-[2rem] mt-[1rem]'>
       <img src="/images/Dubs-web-logo.png"/>

       <div className='flex w-full item-center justify-center'>
       <h1 className='ml-[-15rem]'>Dubs Community Guidelines</h1>
       </div>
      
        </div>
        </div> */}
          {/* <div className="dubs-mobile-logo">
                <div className='logo-header mt-5 flex justify-center'>
                    <img src="/images/dubslogo.svg" />
                </div>
                <div className='header flex mt-1  justify-center'>
                    <h1>Dubs Community Guidelines</h1>

                </div>
                </div>
            </div> */}
          <div className="Guideline-page  mt-[2rem] mb-[2rem] mx-5">
            {/* <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>1. Mission Statement</p>
            </div> */}
            {/* <div className="sub-description">
              <div className="sub-data">
                <p>
                  Welcome to Dubs—a place where gaming brings everyone together.
                  Whether you’re here to dominate the leaderboards, chill with
                  friends, or just vibe with like-minded gamers, we’ve got you
                  covered. At Dubs, we believe everyone should feel included,
                  respected, and safe while playing and connecting. It’s all
                  about building a fun, positive space where you can truly be
                  yourself. Ready to join the fam? @joindubs
                </p>
              </div>
            </div> */}
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  Welcome to DUBS—a place where gaming brings everyone together.
                  Whether you’re here to dominate the leaderboards, chill with
                  friends, or just vibe with like-minded gamers, we’ve got you
                  covered. At DUBS, we believe everyone should feel included,
                  respected, and safe while playing and connecting. Your safety
                  is non-negotiable. It’s all about building a fun, positive
                  space where you can truly be yourself.
                </p>
              </div>
            </div>
            {/* <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>2. Core Values</p>
            </div> */}
            {/* <div className="sub-description">
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    <p className="strong inline-block">Togetherness:</p> We’re
                    stronger when we game together. At Dubs, we embrace gamers
                    from all walks of life, making sure everyone feels at home.
                  </li>

                  <li>
                    <p className="strong inline-block">Camaraderie:</p> We’ve
                    got each other’s backs. Positivity and respect are the name
                    of the game. No drama—just good vibes and support.
                  </li>
                  <li className="pl-4">
                    <p className="strong inline-block">Player Power:</p> You’re
                    in control. Customize your experience, express your
                    creativity, and be proud of who you are as a gamer.
                  </li>
                </ul>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>3. Safety & Privacy</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  Your safety is non-negotiable. We’re here to keep Dubs a
                  secure space for all. Transparency is key, so we’ll always
                  keep you in the loop about how we handle things.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>4. Celebrate Every Win</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  No victory is too small! Whether it’s your first game or your
                  100th, Dubs is all about celebrating what you achieve. We hype
                  each other up because gaming is more fun when we win together.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>5. Community Conduct</p>
            </div> */}
            <div className="sub-description">
              <div className="sub-data">
                <div className="sub-heading">
                  <p className="!font-bold">Community Conduct</p>
                </div>
                <div className="sub-heading">
                  <p className="!font-bold">What’s a No-Go:</p>
                </div>
              </div>
              <div className="sub-data">
                <p className="strong">1. Harassment & Hate Speech</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Using your beliefs to put down or attack others? That’s a
                    hard pass.
                  </li>
                  <li className="pl-4">
                    No hate allowed. We do NOT tolerate discrimination based on
                    race, gender identity, sexual orientation, religion, or
                    anything else that makes someone who they are.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">2. Bullying and Toxic Behavior</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Bullying, trash talk, or negative vibes? Nope. This isn’t
                    the place for it. No one’s here to hear insults about their
                    skills, looks, or personality.
                  </li>
                  <li className="pl-4">
                    We’re all about constructive feedback and lifting each other
                    up.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">3. Inappropriate Language & Content</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Get hyped, but keep it clean. Be mindful of younger players
                    and watch the language. No need for slurs, hate speech, or
                    anything that crosses the line.
                  </li>
                  <li className="pl-4">Personal attacks? Not here.</li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">4. Cheating & Exploits</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Cheaters never prosper. Hacks, exploits, or shady play are
                    banned. Play fair or don’t play at all.
                  </li>
                  <li className="pl-4">
                    Sharing cheats or breaking the game ruins it for everyone
                    and will get you booted.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">5. Sexual Misconduct & Exploitation</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Unwanted messages, inappropriate content, or anything
                    creepy? We have zero tolerance. It’s simple: respect
                    boundaries, and don’t cross lines.
                  </li>
                  <li className="pl-4">
                    Child exploitation or anything similar? You’re gone, and
                    we’ll report it to the authorities.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">6. Self Harm & Threats of Violence</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Content that promotes self-harm, suicide, or violence? Not
                    here. We’re a positive space, and there’s no room for
                    harmful stuff.
                  </li>
                  <li className="pl-4">
                    Graphic violence? Take it somewhere else—it’s not welcome on
                    DUBS.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">7. Privacy First</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Keep personal info personal. Don’t share anyone’s private
                    details (like phone numbers, addresses, or socials) without
                    their okay.
                  </li>
                  <li className="pl-4">
                    Respect other people’s privacy, just like you’d want yours
                    respected.
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>6. Community Engagement</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p className="strong">1. Constructive Conversations</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Disagree? Cool. But keep it respectful. We’re here to learn
                    from each other, not tear anyone down.
                  </li>
                  <li className="pl-4">
                    No gatekeeping or elitist attitudes. Everyone belongs here.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">2. Helping New Players</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    New to the community? No worries, we’re here to help. Guide
                    others through the platform and offer advice.
                  </li>
                  <li className="pl-4">Helping each other makes us stronger.</li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">3. Celebrate Success</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Every win is worth celebrating. Congratulate others when
                    they achieve something big (or small) and share your wins
                    too! Positivity is contagious.
                  </li>
                </ul>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>7. Enforcement and Moderation</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p className="strong">1. Clear & Fair Moderation</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Our mods are here to keep Dubs safe for everyone. When we
                    take action, we’ll let you know why, so everything stays
                    transparent.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">2. Report It</p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    See something that’s off? Let us know! Reporting helps keep
                    Dubs a great place to be. We rely on you to make sure things
                    stay fun and friendly.
                  </li>
                </ul>
              </div>
              <div className="sub-data">
                <p className="strong">
                  3. Zero Tolerance for Extreme Violations
                </p>
              </div>
              <div className="sub-data">
                <ul className="list-inside">
                  <li className="pl-4">
                    Harassment, hate speech, or violence? Instant action.
                    Depending on how serious it is, you could face suspension or
                    an outright ban.
                  </li>
                </ul>
              </div>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  We’re building a community where everyone can feel safe, have
                  fun, and grow. Let’s keep Dubs a place where positivity
                  reigns, and toxic behavior has no home!
                </p>
              </div>
            </div> */}
          </div>
          <Footer />
        </div>
      </body>
    </>
  );
};
export default Guideline;
